<template>
  <div class="mb-1">
    <b-card
      v-if="itemView!=='list-view'"
      img-top
      class="ecommerce-card offer h-100"
      no-body
    >
      <div class="offer-image-wrapper">
        <b-img-lazy
          :alt="`${offer.name}-${offer.id}`"
          fluid
          class="card-img-left"
          :src="getFirstGalleryImage(offer)"
          @error.native="$event.target.src=defaultImage"
        />
      </div>

      <ProfileAvatar
        :user="offer.user"
        avatar-size="114"
        variant="light-secondary"
        class="mb-2"
      />

      <b-card-body>
        <b-card-text class="text-center pt-2">
          <h5>{{ offer.user.name }}</h5>
        </b-card-text>
        <b-card-text>
          <div class="d-flex justify-content-center align-items-center">
            <profile-languages
              :user="offer.user"
            />
            <profile-rating />
          </div>
        </b-card-text>

        <b-card-text>
          <offer-about
            :about-data="offer"
            :short="true"
          />
        </b-card-text>

      </b-card-body>

      <!-- Product Actions -->
      <div class="item-options text-center ">
        <div class="item-wrapper" />
        <!--            <b-button-->
        <!--              variant="light"-->
        <!--              tag="a"-->
        <!--              class="btn-wishlist"-->
        <!--              @click="toggleProductInWishlist(user)"-->
        <!--            >-->
        <!--              <feather-icon-->
        <!--                icon="HeartIcon"-->
        <!--                class="mr-50"-->
        <!--                :class="{'text-danger': user.isInWishlist}"-->
        <!--              />-->
        <!--              <span>Wishlist</span>-->
        <!--            </b-button>-->
        <b-button
          variant="primary"
          tag="a"
          class="btn-cart"
          @click="handleProviderViewActionClick(offer.user)"
        >
          <feather-icon
            icon="FileTextIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Read more') }}</span>
        </b-button>

      </div>
      <!--/ follower projects rank -->
    </b-card>
    <b-card
      v-else
      no-body
      class="h-100 cursor-pointer"
      :style="{backgroundImage:`url(${getFirstGalleryImage(offer)})`}"
      @click="handleProviderViewActionClick(offer.user)"
    >
      <div
        class="h-100 background"
      >
        <b-row
            no-gutters
            class="justify-content-end"
        >
          <profile-languages
              :user="offer.user"
          />

        </b-row>
        <b-row no-gutters>
          <b-col cols="12">
            <div class="d-flex justify-content-start align-items-center">
              <ProfileAvatar
                  :user="offer.user"
                  avatar-size="48"
                  variant="light-secondary"
              />
              <h5>{{ offer.user.name }}</h5>
              <profile-rating />
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col
              cols="12"
              class="mx-50"
          >

            <b-badge
                v-for="purpose in offer.point_purposes"
                :key="purpose.slug"
                pill
                variant="light-primary"
            >
              {{ getPurposeTitle(purpose.slug) }}
            </b-badge>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>

</template>
<script>
import {
  BCard, BAvatar, BBadge, BButton, BCardText, BCardBody, BImgLazy, BCol, BRow,
} from 'bootstrap-vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'
import { getUserAvatarText } from '@/mixins/functions'
import { useUserUi } from '@/views/apps/user/useUser'
import OfferAbout from '@/views/apps/service/offer/details/OfferAbout.vue'
import { serviceOptions } from '@/mixins/options'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BCardText,
    BCardBody,
    BImgLazy,
    BCol,
    BRow,
    ProfileAvatar,
    ProfileLanguages,
    ProfileRating,
    OfferAbout,
  },
  mixins: [getUserAvatarText],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    itemView: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      handleProviderViewActionClick,
    } = useUserUi()

    const defaultImage = require('@/assets/images/svg/service-noimage.svg')
    const getFirstGalleryImage = point => (point.media_files !== undefined && point.media_files.length ? point.media_files[0].url : defaultImage)
    const { getPurposeTitle } = serviceOptions
    return {
      handleProviderViewActionClick,
      getFirstGalleryImage,
      defaultImage,
      getPurposeTitle,
    }
  },
}
</script>
<style lang="scss">
.ecommerce-card.offer .profile-image{
  position: absolute!important;
  top: 9rem!important;
}
.ecommerce-card.offer .profile-image-wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.b-avatar.badge-light-secondary {
  background-color: rgba(240, 240, 241, 1);
}
.offer-image-wrapper{
  height: 14rem;
  overflow: hidden;
  opacity: 0.10;
}
#providers-list .card .background{
  background: rgba(255, 255, 255, 0.95);
  border-radius: 0.428rem;
}
#providers-list .card{
  border-radius: 0.528rem;
  background-size:cover;
}
</style>
