<template>
  <div>
    <v-select
      id="pointPurposes"
      ref="pointPurposes"
      v-model="pointPurposes"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :placeholder="$t('point.which_service_do_you_require?')"
      label="item_data"
      multiple
      :filterable="false"
      :loading="fetchingPointPurposes"
      :selectable="() => selectable()"
      :options="optionsPointPurposes"
      @search="(search, loading) => {
        if(search.length > 2){
          loading(true)
          onSearchDebounced(search, loading)}
      }"
    >

      <template #option="{ title, point_type }">
        <div class="text-wrap">
          <strong> {{ title }}</strong>
        </div>
        <small> ({{ getTypeTitle(point_type.slug) }})</small>
      </template>
      <template #selected-option="{ title }">
        <div class="text-wrap">
          {{ title }}
        </div>
      </template>
      <template v-slot:no-options="{ search, searching,loading }">
        <span />
        <em
          v-if="search.length && !loading"
          style="opacity: 0.5"
        >{{ $t('common.no_results_found_for') }}: {{ search }}</em>
        <em
          v-else
          style="opacity: 0.5"
        > {{ $t('common.start_typing_to_search') }}</em>

      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { usePointRemoteData } from '@/views/apps/service/usePoints'
import i18n from '@/libs/i18n'
import { serviceOptions } from '@/mixins/options'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
    vSelect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      pointPurposes: [],
      lastAddedPurpose: {},
    }
  },
  watch: {
    '$i18n.locale': function () {
      if (this.pointPurposes) {
        this.setOptionsPointPurposes({}, this.optionsPointPurposes)
        this.pointPurposes = this.getTranslatedPointPurposes(this.pointPurposes)
      }
    },
    '$store.state.point.filter': function (newVal, oldVal) {
      if (!('point_purposes' in newVal)) return

      this.filterPurposesByRequest(newVal)
    },

    pointPurposes(newValue, oldValue) {
      if (!newValue.length) {
        this.$store.commit('point/setFilter', {})
        return
      }

      if (this.lastAddedPurpose.id === newValue.slice(-1)[0].id) return

      this.lastAddedPurpose = { ...newValue.slice(-1)[0] }

      // Delete all purposes that are of a different point type
      if (oldValue.length) {
        const previousAddedPurpose = oldValue.slice(-1)[0]

        if (previousAddedPurpose.point_type.id !== this.lastAddedPurpose.point_type.id) {
          this.pointPurposes = this.pointPurposes.filter(el => el.point_type.id !== previousAddedPurpose.point_type.id)
        }
      }

      this.filters.pointPurposes = this.pointPurposes.map(el => el.id)
      this.$store.commit('point/setFilter', { point_type: this.lastAddedPurpose.point_type.id, point_purposes: this.filters.pointPurposes })
    },
  },

  methods: {
    selectable() {
      return true
    },
    filterPurposesByRequest(query) {
      if (!this.optionsPointPurposes || !this.optionsPointPurposes.find(el => el.point_type.id === query.point_type)) {
        this.fetchFilteredPurposesByPointType(query.point_type).then(() => {
          this.pointPurposes = this.optionsPointPurposes.filter(el => query.point_purposes.includes(el.id))
        })
      } else {
        this.pointPurposes = this.optionsPointPurposes.filter(el => query.point_purposes.includes(el.id))
      }
    },

  },
  setup() {
    const optionsPointPurposes = ref([])
    const fetchingPointPurposes = ref(false)
    const { getPurposeTitle, getTypeTitle, getTranslatedPointPurposes } = serviceOptions
    const { fetchPointPurposes } = usePointRemoteData()

    const setOptionsPointPurposes = async (args, data) => {
      const optionsList = data
      optionsList.map(el => {
        el.item_data = [el.title, args.q].join(' ')
        return el.item_data
      })
      optionsPointPurposes.value = await getTranslatedPointPurposes(optionsList)
      return optionsPointPurposes.value
    }

    function fetchFilteredPurposesByPointType(pointTypeIds) {
      fetchingPointPurposes.value = true
      const args = {
        point_type_ids: pointTypeIds,
      }
      return new Promise((resolve, reject) => {
        fetchPointPurposes(args)
          .then(response => {
            setOptionsPointPurposes(args, response.data.data)
            return resolve(true)
          })
          .catch(error => reject(error)).finally(() => {
            fetchingPointPurposes.value = false
          })
      })
    }

    const onSearchDebounced = useDebounceFn(async (search, loading) => {
      const args = {
        q: search,
        lang: i18n.locale,
      }

      return fetchPointPurposes(args)
        .then(async response => setOptionsPointPurposes(args, response.data.data))
        .catch(error => console.log(error)).finally(() => loading(false))
    }, 1000)

    return {
      optionsPointPurposes,
      fetchingPointPurposes,
      getPurposeTitle,
      getTypeTitle,
      getTranslatedPointPurposes,
      onSearchDebounced,
      fetchFilteredPurposesByPointType,
      setOptionsPointPurposes,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped lang="scss">
.vs--disabled{
  color: #00cfe8 !important;
}

</style>
